import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import { CustomModal } from "../../../../components/Modal";
import ScheduleForm from "../../../../components/Forms/Dashboard/_clinics/Schedule";
import scheduleService from "../../../../services/clinicScheduleService";
import roleService from "../../../../services/clinicRoleService";
import patientService from "../../../../services/clinicPatientService";
import { startOfWeek, endOfWeek, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAuth } from "../../../../context/authContext";
import { translateAppointmentStatus } from "../../../../config/utils";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function Schedule() {
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showModalEditAdd, setshowModalEditAdd] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [patients, setPatients] = useState([]);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    patient: "",
    professional: "",
  });
  const [item, setItem] = useState({
    timeOfSession: "",
    startTime: "",
    endTime: "",
    lunchStartTime: "",
    lunchEndTime: "",
    lunchTime: false,
    pauseTime: false,
    pauses: 0,
  });
  const [selectedDays, setSelectedDays] = useState([]);
  const { user } = useAuth();

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const getCorrectWeek = (selectedDate) => {
    const startOfThisWeek = startOfWeek(selectedDate, { weekStartsOn: 0 });
    const endOfThisWeek = endOfWeek(startOfThisWeek, { weekStartsOn: 0 });

    return {
      startOfThisWeek,
      endOfThisWeek,
    };
  };

  const selectedDate = filters.startDate
    ? new Date(filters.startDate)
    : new Date();

  const { startOfThisWeek, endOfThisWeek } = getCorrectWeek(selectedDate);

  const formattedStart = format(startOfThisWeek, "dd'/'MMMM", { locale: ptBR });
  const formattedEnd = format(endOfThisWeek, "dd'/'MMMM", { locale: ptBR });

  const weekDays = [
    { id: "monday", namePt: "Segunda-feira" },
    { id: "tuesday", namePt: "Terça-feira" },
    { id: "wednesday", namePt: "Quarta-feira" },
    { id: "thursday", namePt: "Quinta-feira" },
    { id: "friday", namePt: "Sexta-feira" },
    { id: "saturday", namePt: "Sábado" },
    { id: "sunday", namePt: "Domingo" },
  ];

  const refreshItems = async () => {
    setshowModalEditAdd(false);
    setLoading(false);
    setItem({});
    setSelectedDays([]);
    setErrorMessage("");
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const formattedStartOfThisWeek = format(startOfThisWeek, "yyyy-MM-dd");
      const formattedEndOfThisWeek = format(endOfThisWeek, "yyyy-MM-dd");

      const result = await scheduleService.fetchData({
        startOfWeek: formattedStartOfThisWeek,
        endOfWeek: formattedEndOfThisWeek,
        patient: filters.patient,
        professional: filters.professional,
      });

      if (firstLoad && user.clinicRole !== "user") {
        await Promise.all([
          roleService.getProfessionals(),
          patientService.getPatients(),
        ]).then((values) => {
          setProfessionals(values[0]);
          setPatients(values[1]);
        });
      }

      setLoading(false);
      setData(result);
      setFirstLoad(false);
    } catch (error) {
      console.error("Erro ao buscar horários:", error);
      setLoading(false);
    }
    await refreshItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    document.title = "We Care Sync - Agenda";
    fetchData();
  }, [fetchData]);

  const toggleDaySelection = (dayId) => {
    const updatedSelectedDays = selectedDays.includes(dayId)
      ? selectedDays.filter((day) => day !== dayId)
      : [...selectedDays, dayId];
    setSelectedDays(updatedSelectedDays);
  };

  const handleInputChange = (event) => {
    const { name, type } = event.target;
    const value =
      type === "checkbox" ? event.target.checked : event.target.value;

    if (name === "timeOfSession") {
      const numericValue = parseInt(value, 10);
      if (isNaN(numericValue)) return;
      setItem({ ...item, [name]: numericValue > 60 ? 60 : numericValue });
    } else if (name === "pauses") {
      const numericValue = parseInt(value, 10);
      if (isNaN(numericValue)) return;
      setItem({
        ...item,
        [name]: numericValue > 4 ? 4 : numericValue < 1 ? 1 : numericValue,
      });
    } else {
      setItem({ ...item, [name]: value });
    }
  };

  const submitData = async () => {
    setLoading(true);
    item.weekDays = selectedDays;
    try {
      await scheduleService.submitData(item);
      Swal.fire("Sucesso!", "Horário adicionado com sucesso!", "success");
      fetchData();
    } catch (error) {
      setErrorMessage(error.message);
      Swal.fire("Erro!", error.message, "error");
    } finally {
      setLoading(false);
      refreshItems();
    }
  };

  const exportAgendaToPDF = () => {
    const input = document.getElementById("agenda-pdf");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`AGENDA - ${formattedStart} - ${formattedEnd}.pdf`);
    });
  };

  return (
    <>
      <Sidebar pageName="Agenda" pageUrl="/agenda">
        <Container fluid className="px-4">
          <Row className="mt-2 mb-4">
            <Col xs={12} className="d-flex align-items-end justify-content-end">
              {user.clinicRole === "owner" && (
                <Button
                  className="custom-btn btn-app btn-add-solo d-flex align-items-center justify-content-center"
                  style={{ width: "33%" }}
                  onClick={() => {
                    setshowModalEditAdd(true);
                    setItem({});
                    setSelectedDays([]);
                    setErrorMessage("");
                  }}
                >
                  <span
                    className="material-icons p-0 me-2"
                    style={{ fontSize: 20 }}
                  >
                    add_circle_outline
                  </span>
                  {Object.keys(data).length === 0 ? "Adicionar" : "Alterar"}{" "}
                  Horários
                </Button>
              )}
            </Col>
          </Row>
          <Row className="justify-content-end mb-4">
            <Col xs={12} sm={3}>
              <small>Paciente</small>
              <select
                className="form-control input-search"
                name="patient"
                value={filters.patient}
                onChange={handleFilterChange}
              >
                <option value="">Todos</option>
                {patients.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.fullName}
                  </option>
                ))}
              </select>
            </Col>
            <Col xs={12} sm={3}>
              <small>Profissional</small>
              <select
                className="form-control input-search"
                name="professional"
                value={filters.professional}
                onChange={handleFilterChange}
              >
                <option value="">Todos</option>
                {professionals.map((item, index) => (
                  <option value={item._id} key={index}>
                    {`${item.user?.fullName}, ${item.profession}`}
                  </option>
                ))}
              </select>
            </Col>
            <Col xs={12} sm={3}>
              <small>Data de Início</small>
              <input
                type="date"
                className="form-control input-search"
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              <Button
                className="custom-btn btn-app btn-add-solo d-flex align-items-center justify-content-center"
                style={{ width: "33%" }}
                onClick={exportAgendaToPDF}
              >
                <span
                  className="material-icons p-0 me-2"
                  style={{ fontSize: 20 }}
                >
                  picture_as_pdf
                </span>
                Exportar Agenda
              </Button>
            </Col>
          </Row>
          <Row id="agenda-pdf">
            <Col xs={12} className="my-4">
              <h3 className="text-center">
                de{" "}
                <span className="color-primary fw-bold">{formattedStart}</span>{" "}
                até{" "}
                <span className="color-primary fw-bold">{formattedEnd}</span>
              </h3>
            </Col>
            {data && Object.keys(data).length > 1 ? (
              weekDays.map(
                (day) =>
                  data[day.id] &&
                  data[day.id].length > 0 && (
                    <Col key={day.id}>
                      <Card className="h-100">
                        <Card.Header>
                          <h5 className="text-center">{day.namePt}</h5>
                        </Card.Header>
                        <Card.Body>
                          {data[day.id].map((session) => (
                            <Row key={session._id} className="mb-2">
                              <Col xs={12}>
                                <p className="m-0">{`${session.startTime} - ${session.endTime}`}</p>
                                {session.appointments.length > 0 &&
                                  session.appointments.map(
                                    (appointment, appIndex) => (
                                      <>
                                        <small
                                          key={appointment._id || appIndex}
                                          className="text-muted"
                                        >
                                          {`${
                                            appointment.patientName.split(
                                              " "
                                            )[0]
                                          } - ${
                                            filters.patient !== ""
                                              ? `${
                                                  appointment.professional.split(
                                                    " "
                                                  )[0]
                                                } (${
                                                  appointment.professionalProfession
                                                })`
                                              : appointment.professional.split(
                                                  " "
                                                )[0]
                                          } - `}
                                          <span
                                            className={`fw-bold text-${translateAppointmentStatus(
                                              "color",
                                              appointment.status
                                            )}`}
                                          >
                                            {translateAppointmentStatus(
                                              "label",
                                              appointment.status
                                            )}
                                          </span>
                                        </small>
                                        <br />
                                      </>
                                    )
                                  )}
                              </Col>
                            </Row>
                          ))}
                        </Card.Body>
                      </Card>
                    </Col>
                  )
              )
            ) : (
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center text-muted small"
              >
                Não há horários cadastrados.
              </Col>
            )}
          </Row>
        </Container>
        <CustomModal
          show={showModalEditAdd}
          onHide={() => {
            refreshItems();
          }}
          title={"Adicionar Horários"}
          submitLabel={"Enviar informações"}
          onSubmit={submitData}
          errorMessage={errorMessage}
          disabled={selectedDays.length > 0 ? false : true}
          type="form"
        >
          <Row className="mb-4">
            <Col xs={12} className="d-flex">
              <h6 className="fw-bold">Selecione os dias da semana:</h6>
            </Col>
            {weekDays.map((day) => (
              <Col
                xs={12}
                md={"auto"}
                key={day.id}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className={`label-day ${
                    selectedDays.includes(day.id) ? "selected" : ""
                  }`}
                  onClick={() => toggleDaySelection(day.id)}
                >
                  {day.namePt}
                </div>
              </Col>
            ))}
          </Row>
          {selectedDays.length > 0 && (
            <>
              <Row className="mb-2">
                <Col xs={12}>
                  <h6 className="fw-bold">
                    Baseado nos dias selecionados, insira as informações abaixo:
                  </h6>
                </Col>
              </Row>
              <ScheduleForm
                item={item}
                handleInputChange={handleInputChange}
                days={selectedDays}
              />
            </>
          )}
        </CustomModal>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Schedule;
