import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Navbar,
  Nav,
  Accordion,
  Card,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import authService from "../../services/authService";
import clinicService from "../../services/clinicService";
import { useAuth } from "../../context/authContext";
import { getFirstName, getCompanyName } from "../../config/utils";
import Loading from "../Loading";
import { CustomModalClinics } from "../Modal";
import Logo from "../../assets/img/logo-em-pe-white.png";

function Sidebar(props) {
  const {
    user,
    selectedClinic,
    changeSelectedClinic,
    changeSelectedClinicAdmin,
  } = useAuth();
  const [sidebar, setSidebar] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clinics, setClinics] = useState([]);

  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (isMobile) {
      setSidebar(false);
    }
  }, [isMobile]);

  const toggleSidebar = () => {
    setSidebar((prevSidebar) => !prevSidebar);
  };

  const logout = async () => {
    try {
      await authService.logout();
      window.location.href = "/login";
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  const getClinics = async () => {
    setLoading(true);
    try {
      await clinicService
        .findData()
        .then((result) => {
          setLoading(false);
          setClinics(result);
          setShowModal(true);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (error) {
      console.error("Erro ao buscar cliínicas:", error);
      setLoading(false);
    }
  };

  const changeClinic = (clinicId) => {
    setLoading(true);
    if (user.role === "admin") {
      let item = {};
      if (clinicId !== "") {
        item = clinics.find((clinic) => clinic._id === clinicId);
      }
      changeSelectedClinicAdmin(item);
    } else {
      changeSelectedClinic(clinicId);
    }

    setLoading(false);
    setShowModal(false);
  };

  return (
    <div className={sidebar ? "d-flex" : "d-flex toggled"} id="wrapper">
      <div id="sidebar-wrapper">
        <div className="sidebar-heading d-flex align-items-center justify-content-center flex-column">
          <div
            className="position-fixed d-block d-sm-none"
            onClick={toggleSidebar}
            style={{ top: 10, right: 10, color: "#fff" }}
          >
            <span className="material-icons">close</span>
          </div>
          <Link to="/dashboard">
            {" "}
            <div className="logo-company">
              <img
                src={selectedClinic?.logoUrl || Logo}
                alt=""
                className="img-fluid rounded"
              />
            </div>
          </Link>
        </div>
        <Accordion>
          {/* DASHBOARD */}
          <Card className="border-0">
            <Card.Header className="p-0 border-0">
              {/* ADMIN */}
              {user?.role === "admin" && !selectedClinic && (
                <>
                  <NavItem
                    icon={"dashboard"}
                    name={"Dashboard"}
                    link="/dashboard-admin"
                  />
                  <NavItem
                    icon={"people_alt"}
                    name={"Usuários"}
                    link="/users"
                  />
                  <NavItem
                    icon={"business"}
                    name={"Clínicas"}
                    link="/clinics"
                  />
                  <NavItem
                    icon={"mail"}
                    name={"Convites"}
                    link="/invitations"
                  />
                  <NavItem icon={"person_add"} name={"Leads"} link="/leads" />
                </>
              )}

              {selectedClinic && (
                <>
                  {(user?.clinicRole === "owner" ||
                    user?.clinicRole === "admin" ||
                    user?.role === "admin") && (
                    <>
                      <NavItem
                        icon={"dashboard"}
                        name={"Dashboard"}
                        link="/dashboard"
                      />
                      <NavItem icon={"event"} name={"Agenda"} link="/agenda" />
                    </>
                  )}

                  {(user?.clinicRole === "owner" || user?.role === "admin") && (
                    <NavItem
                      icon={"paid"}
                      name={"Financeiro"}
                      outlined
                      link="/financeiro"
                    />
                  )}

                  <NavItem
                    icon={"event_note"}
                    name={"Atendimentos"}
                    link="/atendimentos"
                  />

                  {user?.role === "user" && (
                    <NavItem
                      icon={"event_available"}
                      name={"Meus atendimentos"}
                      link="/meus-atendimentos"
                    />
                  )}

                  <NavItem
                    icon={"diversity_2"}
                    name={"Pacientes"}
                    link="/pacientes"
                  />

                  {(user?.clinicRole === "owner" ||
                    user?.clinicRole === "admin" ||
                    user?.role === "admin") && (
                    <>
                      <NavItem
                        icon={"auto_graph"}
                        name={"Evoluções"}
                        link="/evolucoes"
                      />
                      <NavItem
                        icon={"groups"}
                        name={"Profissionais"}
                        link="/profissionais"
                      />
                    </>
                  )}
                </>
              )}
            </Card.Header>
          </Card>
        </Accordion>
        <div className="sidebar-logout-container">
          <div className="d-flex flex-column justify-content-center color-white clinic-div">
            <small className="color-white ms-3">Clínica Selecionada:</small>
            <div
              className="d-flex w-100 selected-clinic c-pointer py-2"
              onClick={() => {
                getClinics();
              }}
            >
              {selectedClinic ? (
                <strong className="ps-3">
                  {selectedClinic.legalName &&
                    getCompanyName(selectedClinic.legalName, 28)}
                </strong>
              ) : (
                <strong className="ps-3">Nenhuma selecionada</strong>
              )}
            </div>
          </div>
          <hr className="hr-sidebar" />
          <LogoutItem
            icon={"logout"}
            name={"Logout"}
            className={"c-pointer"}
            onClick={logout}
          />
        </div>
      </div>
      <div
        id="page-content-wrapper"
        className="d-flex justify-content-between flex-column"
      >
        <div className="d-flex flex-column w-100 h-100 overflow-auto justify-content-between">
          <div>
            <Navbar
              collapseOnSelect
              expand="lg"
              className="py-3 bg-color-light-gray justify-content-between"
            >
              <div className="ms-3 d-flex justify-content-center align-items-center">
                <span
                  className="material-icons c-pointer"
                  onClick={toggleSidebar}
                >
                  reorder
                </span>
                <h6 className="ms-3 mt-1 mb-0 lh-1">
                  <a
                    href={props.pageUrl}
                    className="text-decoration-none me-2 color-dark-gray fw-bold menu"
                  >
                    {props.pageName}
                  </a>
                </h6>
              </div>
              <Nav className="ms-auto me-3 me-md-0 p-0">
                <Nav.Link
                  className="p-0 d-flex d-sm-none color-dark-gray"
                  href="#"
                  onClick={logout}
                  style={{ color: "#666666" }}
                >
                  Sair
                </Nav.Link>
                <DropdownButton
                  align={{ lg: "end" }}
                  title={
                    <>
                      {user && (
                        <span className="color-dark-gray me-3">
                          Olá, <strong>{getFirstName(user.fullName)}</strong>!
                        </span>
                      )}
                    </>
                  }
                  id="dropdown-menu-align-right"
                  className="p-0 d-none d-sm-flex"
                >
                  <Dropdown.Item eventKey="4" onClick={logout}>
                    Sair
                  </Dropdown.Item>
                </DropdownButton>
              </Nav>
            </Navbar>
            <div className="p-sm-4 px-md-0 py-md-2 background-white">
              {props.children}
            </div>
          </div>
          <div className="w-100 py-3 text-center small">
            <p className="color-dark-gray">
              © {new Date().getFullYear()} - We Care Sync. Todos os Direitos
              Reservados. Criado por{" "}
              <a
                className="color-dark-gray font-semi-bold text-decoration-none"
                href="https://starchild.com.br"
                target="_blank"
                rel="noreferrer"
              >
                starchild
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <CustomModalClinics show={showModal} onHide={() => setShowModal(false)}>
        <select
          className="form-control input-search"
          value={selectedClinic?._id || ""}
          onChange={(e) => changeClinic(e.target.value)}
        >
          {user?.role === "admin" && (
            <option
              value=""
              disabled={
                user?.role === "admin" && selectedClinic?._id ? false : true
              }
            >
              Não selecionar clínica
            </option>
          )}
          {clinics.map((option, index) => (
            <option key={index} value={option._id}>
              {option.fullName}
            </option>
          ))}
        </select>
      </CustomModalClinics>
      <Loading show={loading} />
    </div>
  );
}

function NavItem(props) {
  const location = useLocation();
  const isActive = location.pathname === props.link;

  return (
    <div className="list-group list-group-flush">
      <Link
        className={`list-group-item list-group-item-action ${
          isActive ? `item-active` : ``
        }`}
        to={props.link}
      >
        <span className="d-flex align-items-center">
          <span
            className={`${
              props.outlined ? `material-icons-outlined` : `material-icons`
            }`}
          >
            {props.icon}
          </span>
          <span className="name-list">{props.name}</span>
        </span>
      </Link>
    </div>
  );
}

function LogoutItem(props) {
  const { onClick } = props;

  return (
    <div className={`list-group list-group-flush ${props.className}`}>
      <div className="list-group-item list-group-item-action" onClick={onClick}>
        <span className="d-flex align-items-center">
          <span className="material-icons">{props.icon}</span>
          <span className="name-list">{props.name}</span>
        </span>
      </div>
    </div>
  );
}

export default Sidebar;
