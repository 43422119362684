import React, { createContext, useContext, useState, useEffect } from "react";
import authService from "../services/authService";
import clinicService from "../services/clinicService";
import Loading from "../components/Loading";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(() => {
    const savedClinic = localStorage.getItem("selectedClinic");
    return savedClinic ? JSON.parse(savedClinic) : null;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const userInfo = await authService.getUserInfo();
        setUser(userInfo.user);

        if (userInfo.user.activeClinic) {
          setSelectedClinic(userInfo.user.activeClinic);
          localStorage.setItem(
            "selectedClinic",
            JSON.stringify(userInfo.user.activeClinic)
          );
        } else if (userInfo.user.clinics?.length > 0) {
          const fallback = userInfo.user.clinics[0];
          await clinicService.selectClinic(fallback._id);
          setSelectedClinic(fallback);
          localStorage.setItem("selectedClinic", JSON.stringify(fallback));
        } else {
          setSelectedClinic(null);
          localStorage.removeItem("selectedClinic");
        }
      } catch (e) {
        console.error(e);
        setError(e);
        localStorage.clear();
        setUser(null);
        setSelectedClinic(null);
      } finally {
        setIsLoading(false);
      }
    };

    loadUser();
  }, []);

  const updateSelectedClinic = (clinic) => {
    if (clinic) {
      setSelectedClinic(clinic);
      localStorage.setItem("selectedClinic", JSON.stringify(clinic));
    } else {
      setSelectedClinic(null);
      localStorage.removeItem("selectedClinic");
    }
  };

  const changeSelectedClinic = (clinicId) => {
    setIsLoading(true);
    const clinic = user?.clinics?.find((c) => c._id === clinicId);
    if (clinic) {
      updateSelectedClinic(clinic);

      clinicService
        .selectClinic(clinicId)
        .then(() => {
          window.location.replace("/dashboard");
        })
        .catch((error) => {
          console.error(error);
          alert(error.response?.data?.message || "Erro ao selecionar clínica.");
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };

  const changeSelectedClinicAdmin = (item) => {
    setIsLoading(true);
    if (item && item._id) {
      updateSelectedClinic(item);

      clinicService
        .selectClinic(item._id)
        .then(() => {
          window.location.replace("/dashboard");
        })
        .finally(() => setIsLoading(false));
    } else {
      updateSelectedClinic(null);
      clinicService
        .selectClinic(null)
        .then(() => {
          window.location.replace("/dashboard");
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        selectedClinic,
        changeSelectedClinic,
        changeSelectedClinicAdmin,
        isLoading,
        error,
      }}
    >
      {!isLoading ? children : <Loading show={isLoading} />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
