import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useAuth } from "../../../../../context/authContext";

const UserForm = ({ user, handleInputChange, type }) => {
  const userInfo = useAuth().user;

  return (
    <>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Nome
              {!user._id && <sup className="ms-1 text-danger fw-bold">*</sup>}
            </Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              defaultValue={
                type === "user"
                  ? user.user
                    ? user.user.fullName
                    : ""
                  : user.fullName || ""
              }
              onChange={handleInputChange}
              required
              disabled={type === "user" && user._id ? true : false}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Nível de Acesso
              {!user._id && <sup className="ms-1 text-danger fw-bold">*</sup>}
            </Form.Label>
            <Form.Control
              as="select"
              name="role"
              defaultValue={user.role || ""}
              onChange={handleInputChange}
              disabled={
                userInfo.role === "admin" ||
                (userInfo.role === "user" && userInfo.clinicRole === "owner") ||
                userInfo.clinicRole === "admin"
                  ? user.role === "owner"
                    ? true
                    : false
                  : true
              }
              required
            >
              <option value="" disabled>
                Selecione o nível
              </option>
              {[
                { name: "Usuário", value: "user" },
                ...(userInfo.role === "admin" ||
                (userInfo.role === "user" && userInfo.clinicRole === "owner")
                  ? [
                      {
                        name: "Administrativo",
                        value: "admin",
                      },
                      {
                        name: "Proprietário",
                        value: "owner",
                      },
                    ]
                  : []),
              ].map((item, index) => (
                <option value={item.value} key={index}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              E-mail
              {!user._id && <sup className="ms-1 text-danger fw-bold">*</sup>}
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              defaultValue={
                type === "user"
                  ? user.user
                    ? user.user.email
                    : ""
                  : user.email || ""
              }
              onChange={handleInputChange}
              required
              disabled={type === "user" && user._id ? true : false}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Profissão
              {!user._id && <sup className="ms-1 text-danger fw-bold">*</sup>}
            </Form.Label>
            <Form.Control
              type="text"
              name="profession"
              defaultValue={user.profession || ""}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      {user._id &&
        (userInfo.role === "admin" || userInfo.clinicRole === "owner") && (
          <>
            <Row className="mb-0 mb-md-3">
              <Col xs={12} md={6}>
                <Form.Group className="mb-3 mb-md-2">
                  <Form.Label className="text-uppercase">
                    Pagamento p/ Sessão (Plano)
                    {!user._id && (
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="sessionPaymentPlan"
                    value={user.sessionPaymentPlan || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3 mb-md-2">
                  <Form.Label className="text-uppercase">
                    Pagamento p/ Sessão (Particular)
                    {!user._id && (
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="sessionCost"
                    value={user.sessionCost || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
              <Col xs={12} md={6}>
                <Form.Group className="mb-3 mb-md-2">
                  <Form.Label className="text-uppercase">
                    Custo p/ Sessão (Plano)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="sessionCostPlan"
                    value={user.sessionCostPlan || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3 mb-md-2">
                  <Form.Label className="text-uppercase">
                    Custo p/ Sessão (Particular)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="sessionCostParticular"
                    value={user.sessionCostParticular || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
    </>
  );
};

export default UserForm;
